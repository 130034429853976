code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App-link {
  color: #61dafb;
}

.bg-footerBlue {
  background-color: #001E42 !important;
}

.tutoring-section .grid-cols-3 {
  grid-template-columns: 1fr;
}

.menu {
  padding: 20px;
}

.menu span {
  height: 5px;  
}

.button-primary {
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
